import React from 'react';
import Layout from '../layouts/en';
import Contact from '../components/pages/contact';

const ContactPage = props => (
  <Layout {...props}>
    <Contact />
  </Layout>
);

export default ContactPage;
