import React from 'react';
import styled from 'styled-components';

const Form = styled.form`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  label {
    .label {
      display: block;
      margin-bottom: 5px;
      text-align: left;
      font-size: 16px;
    }
    input,
    textarea {
      width: 100%;
      margin-bottom: 20px;
      padding: 10px 10px;
      border: 1px solid #1c1c1c;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
  input[type='submit'] {
    background: hsl(209, 61%, 16%);
    color: hsl(210, 36%, 96%);
    padding: 15px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    border: none;
    border-radius: 4px;
  }
`;

const ContactForm = () => (
  <Form name="contact" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
    <input type="hidden" name="bot-field" />
    <label htmlFor="name">
      <span className="label">Name</span>
      <input type="text" name="name" id="name" />
    </label>

    <label htmlFor="email">
      <span className="label">Email</span>
      <input type="text" name="email" id="email" />
    </label>

    <label htmlFor="message">
      <span className="label">Message</span>
      <textarea name="message" id="message" rows="6" />
    </label>

    <input type="submit" value="Send Message" />
  </Form>
);

export default ContactForm;
