import React from 'react';
import { Spring } from 'react-spring';

import SEO from '../seo';
import ContactForm from '../contactForm';
import MainSection from '../../styles/MainSection';

const Contact = () => (
  <>
    <SEO title="Contact" />
    <MainSection>
      <Spring from={{ opacity: 0, bottom: -40 }} to={{ opacity: 1, bottom: 0 }}>
        {props => (
          <div className="container" style={props}>
            <h1>Get in touch with us</h1>
            <ContactForm />
          </div>
        )}
      </Spring>
    </MainSection>
  </>
);

export default Contact;
